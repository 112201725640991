import React from "react";
import "./App.css";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { Routes, Route } from "react-router-dom";
//pages
import Landing from "./pages/Landing/Landing";
import Contact from "./pages/Contact/Contact";
import TenantRegistration from "./pages/Tenant/Registration";
//
import NoPage from "./pages/NoPage/NoPage";
//components
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

function App() {
  // const notify = () => toast("Wow so easy!");

  return (
    <div className="App">
      <ToastContainer />
      <nav>
        <Navbar />
      </nav>
      <div>
        <Routes>
          <Route path="/" element={<Landing />}></Route>
          <Route path="/tenantreg" element={<TenantRegistration />}></Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
