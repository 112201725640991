import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./Nopage.css";

const NoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="no-page-container">
      <h1 className="no-page-heading">404</h1>
      <p className="no-page-message">
        Oops! The page you’re looking for doesn’t exist.
      </p>
      <Button type="primary" onClick={() => navigate("/")}>
        Go Back Home
      </Button>
    </div>
  );
};

export default NoPage;
