import React from "react";
import "./FeaturesSection.css"; // Make sure to import your CSS file

const FeaturesSection = () => {
  return (
    <div className="App_content">
      <h2 className="FeaturesTitle">Key Features of HabSync</h2>
      <div className="Modules">
        {/* <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/olkhzprmhcmlwjfvxmes"
            alt="Discuss"
          />
          <h3>Discuss</h3>
          <p>Engage in discussions and collaborate with your team.</p>
          <a href="">Learn More</a>
        </div> */}
        <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/v1731601076/to-do-list_-_Copy_z4vi6p.gif"
            alt="My Tasks"
          />
          <h3>My Tasks</h3>
          <p>
            Manage and track personal and work tasks within the system,all
            without relying on third-party tools.
          </p>
          <a href="">Learn More</a>
        </div>
        <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/nfvd1jvkv9xu727d5nlv"
            alt="Dashboard"
          />
          <h3>Dashboard</h3>
          <p>Visualize your company's data and key metrics.</p>
          <a href="">Learn More</a>
        </div>
        <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/i49yntbp3mmzzpt5rmri"
            alt="Inventory"
          />
          <h3>Inventory</h3>
          <p>Manage your inventory efficiently with our tools.</p>
          <a href="">Learn More</a>
        </div>
        <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/coryuyw5rskjzilptfq4"
            alt="Customers"
          />
          <h3>Customers</h3>
          <p>Track and manage your customer relationships.</p>
          <a href="">Learn More</a>
        </div>
        <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/w6ywjhlavrto5ulacxko"
            alt="Suppliers"
          />
          <h3>Suppliers</h3>
          <p>Manage your supplier relationships and orders.</p>
          <a href="">Learn More</a>
        </div>
        {/* <div className="Module">
          <img
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/rgbdwhard2gqcavx1tgl"
            alt="Sales"
          />
          <h3>Sales</h3>
          <p>Track sales activities and manage your pipeline.</p>
          <a href="">Learn More</a>
        </div> */}
      </div>
    </div>
  );
};

export default FeaturesSection;
