import React from "react";
import './Footer.css'; // Make sure this file is updated as well

const Footer = () => {
  return (
    <div className="App-footer">
      <p>&copy; {new Date().getFullYear()} HabSync. All rights reserved.</p>
      <div className="footer-links">
        <a href="/privacy">Privacy Policy</a> | 
        <a href="/terms">Terms of Service</a> | 
        <a href="/contact">Contact Us</a>
      </div>
      <div className="social-media-icons">
        <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
          <img src="/path/to/facebook-icon.png" alt="Facebook" />
        </a>
        <a href="https://twitter.com/yourpage" target="_blank" rel="noopener noreferrer">
          <img src="/path/to/twitter-icon.png" alt="Twitter" />
        </a>
        <a href="https://www.linkedin.com/company/yourpage" target="_blank" rel="noopener noreferrer">
          <img src="/path/to/linkedin-icon.png" alt="LinkedIn" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
