import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

interface tenant {
  Name: string;
  Logo: string;
  TaxID: string;
  Phone: string;
  Mobile: string;
  Address: string;
  Email: string;
  Website: string;
  POBox: string;
}

const addTenant = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_TENANT_SERVICE_API_ADD}`,
      {
        Name: props.Name,
        Mobile: props.Mobile,
        Email: props.Email,
        Address: props.Address,
        REPName: props.REPName,
        REPEmail: props.REPEmail,
        SubscriptionModules: props.subscriptionModules,
      }
    );

    //sessionStorage.set("tenanTID", req.data.id);
    console.log(req);
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // }
    throw err;
  }
};

export { addTenant };
