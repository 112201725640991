import React from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { Button, Menu } from "antd";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <header className="APP-Navbar">
      <div className="saasHeaderTop">
        {/* Logo */}
        <div className="saasLogoDiv" onClick={() => navigate("/")}>
          HabSync
        </div>

        {/* Navigation Links */}
        <div className="saasNavLinks">
          <Menu mode="horizontal">
            <Menu.Item key="features" onClick={() => navigate("/features")}>
              Features
            </Menu.Item>
            <Menu.Item key="pricing" onClick={() => navigate("/pricing")}>
              Pricing
            </Menu.Item>
            <Menu.Item key="about" onClick={() => navigate("/about")}>
              About Us
            </Menu.Item>
            <Menu.Item key="contact" onClick={() => navigate("/contact")}>
              Contact
            </Menu.Item>
          </Menu>
        </div>

        {/* Call to Action Button */}
        <div className="saasMenuActionDiv">
          <Button
            type="primary"
            onClick={() => {
              // navigate("/tenantreg");
              alert("Coming soon stay tuned");
            }}
          >
            Try for Free
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
