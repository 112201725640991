import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

const getAllModule = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_MODULE_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    //console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

export { getAllModule };
