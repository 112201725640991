import React from "react";
import "./Landing.css";
//
import { useNavigate } from "react-router-dom";
//sections
import FeaturesSection from "./Sections/Features/Features";

// import discussImg from "../../assest/modules/discussion.gif";
// import dashboardImg from "../../assest/modules/dashboard.gif";
// import inventoryImg from "../../assest/modules/inventory.gif";
// import customersImg from "../../assest/modules/customer.gif";
// import suppliersImg from "../../assest/modules/ship.gif";
// import salesImg from "../../assest/modules/sales.gif";

const Landing = () => {
  const navigate = useNavigate();
  return (
    <div className="LandingMain">
      <div className="HeroSection">
        <h1 className="Headline">
          Still Managing Your Business with Spreadsheets?
        </h1>
        <h2 className="Subheadline">
          Let HabSync handle the juggling act for you!
        </h2>
        <div className="CallForAction">
          <button
            className="cta-button"
            onClick={() => {
              // navigate("/tenantreg");
              alert("Coming soon stay tuned");
            }}
          >
            Get Started for Free!
          </button>
        </div>
      </div>

      <FeaturesSection />

      <div className="WhoIsThisFor">
        <h2>Who Is HabSync For?</h2>
        <p>
          HabSync is designed to help businesses transition from outdated paper
          and spreadsheet workflows to modern, efficient systems without
          breaking the bank.
        </p>
        <ul className="AudienceList">
          <li>
            <strong>Small to Medium-Sized Enterprises (SMEs):</strong> Ideal for
            businesses looking to streamline operations and reduce reliance on
            traditional methods without high costs.
          </li>
          <li>
            <strong>Startups:</strong> Perfect for startups seeking
            budget-friendly solutions to scale effectively while managing
            resources efficiently.
          </li>
          <li>
            <strong>E-commerce Businesses:</strong> Great for online retailers
            wanting to modernize inventory management and customer relations at
            a competitive price.
          </li>
          <li>
            <strong>Service-Based Companies:</strong> Helps service-oriented
            businesses manage projects and invoicing without complicated setups
            or hefty fees.
          </li>
          <li>
            <strong>Non-Profit Organizations:</strong> Designed to assist
            non-profits in adopting modern workflows that help maximize their
            impact while keeping costs low.
          </li>
          <li>
            <strong>Manufacturers:</strong> Ideal for manufacturers wanting to
            modernize their production planning and supply chain management
            affordably.
          </li>
        </ul>
      </div>

      <div className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="features-grid">
          <div className="feature-item">
            <h3>Designed for Local Businesses</h3>
            <p>
              Built specifically for Ethiopian businesses, our ERP system aligns
              with local market demands and regulations, ensuring a smoother
              experience tailored to your needs.
            </p>
          </div>
          <div className="feature-item">
            <h3>User-Friendly Interface</h3>
            <p>
              Start managing operations right away with our intuitive,
              easy-to-use interface. No extensive training or IT expertise
              needed.
            </p>
          </div>
          <div className="feature-item">
            <h3>Scalable Solutions</h3>
            <p>
              Our ERP system grows with your business. Start small and expand as
              you need, adding advanced features over time.
            </p>
          </div>
          <div className="feature-item">
            <h3>Robust Data Security</h3>
            <p>
              Your data is protected with top-notch security protocols, so you
              can trust that your business information remains confidential and
              secure.
            </p>
          </div>
          <div className="feature-item">
            <h3>Affordable Pricing</h3>
            <p>
              Choose from flexible plans that fit your budget, including a free
              trial. Get the features you need without overextending your
              finances.
            </p>
          </div>
          <div className="feature-item">
            <h3>Exceptional Support</h3>
            <p>
              Our support team is dedicated to helping you, offering
              personalized assistance from onboarding to troubleshooting and
              beyond.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
