import React, { useState, useEffect } from "react";
import "./Registration.css";
//antdeign
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Button, message, Steps, theme } from "antd";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
//
import { useNavigate } from "react-router-dom";
//
//import { localStorage, sessionStorage } from "es-storage";
//api methodes
import { addTenant } from "../../API/tenantAPI";
import { getAllModule } from "../../API/moduleAPI";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//

const TenantRegistration = () => {
  //
  //
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const navigate = useNavigate();
  //
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyPhone, setCompanyPhone] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyFax, setCompanyFax] = useState("");
  const [CompanyTaxID, setCompanyTaxID] = useState("");
  const [CompanyWebSite, setCompanyWebSite] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [ContactPerson, setContactPerson] = useState("");
  const [ContactPersonEmail, setContactPersonEmail] = useState("");
  //modules list state
  const [ModuleList, setModuleList] = useState<any[]>([]);
  //checkbos
  const [CheckUserModule, setCheckUserModule] = useState(false);
  const [checkedModules, setCheckedModules] = useState([]);

  //

  const GetModules = async () => {
    try {
      var req: any = await getAllModule();
      setModuleList(req.data);
      console.log(req);
    } catch (err: any) {
      //console.log(err);
      notifyError(err.response.data);
      throw err;
    }
  };

  useEffect(() => {
    GetModules();
  }, []);
  //

  //Methods
  const HandleNewTenant = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      const call: any = await addTenant({
        Name: CompanyName,
        Mobile: CompanyName,
        Email: CompanyEmail,
        Address: CompanyAddress,
        REPName: ContactPerson,
        REPEmail: ContactPersonEmail,
        subscriptionModules: checkedModules,
      });
      console.log("Api call:", call); // Log the redirect URL

      // Ensure the response contains the expected data
      if (call.data && call.data.TenantUrl) {
        // setLoading(true);
        console.log("Redirect URL:", call.data.TenantUrl); // Log the redirect URL
        // navigate(call.data.TenantUrl, { replace: true });
        window.open(call.data.TenantUrl, "_blank");
      } else {
        // setLoading(false);
        console.error("Invalid API response:", call);
        notifyError("Invalid API response:" + call); // Log an error for debugging
        //alert("Invalid API response. Please contact support.");
      }
    } catch (err: any) {
      // setLoading(false);
      //console.error("Error:", err); // Log the error for debugging
      // alert(
      //   "An error occurred while processing your request. Please try again."
      // );
      console.log(err);
      if (err.response.data) {
        notifyError(err.response.data);
      } else if (err.response.statusText) {
        notifyError(err.response.statusText);
        alert(err.response.statusText);
      }
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };

  //
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle: React.CSSProperties = {
    // height: "100vh",
    // lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const onChangeCheckModule: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    const moduleId: number = e.target.value;
    setCheckedModules((prevCheckedModules: any) => {
      if (prevCheckedModules.includes(moduleId)) {
        return prevCheckedModules.filter((id: number) => id !== moduleId);
      } else {
        return [...prevCheckedModules, moduleId];
      }
    });
    console.log("Checked Modules: ", checkedModules);
  };

  const onChangeCheckInventoryModule: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckUserModule(true);
  };

  const onChangeCheckCustomerModule: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckUserModule(true);
  };

  const onChangeCheckSupplierModule: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckUserModule(true);
  };

  const steps = [
    {
      title: "First",
      content: (
        <div>
          <h2>Pick Your Tools</h2>
          <h3>{checkedModules}</h3>
          <div className="moduleCheckboxContainer">
            {ModuleList.length > 0 ? (
              ModuleList.map((module: any) => (
                <div key={module.id}>
                  <Checkbox value={module.id} onChange={onChangeCheckModule}>
                    <div className="moduleCheckBox">
                      <img
                        className="moduleIcon"
                        src={module.iconUrl}
                        alt={module.name}
                      />
                      {module.name}
                    </div>
                  </Checkbox>
                </div>
              ))
            ) : (
              <div>No Data available</div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Last",
      content: (
        <div>
          <Spin spinning={loading}>
            <form className="form" onSubmit={HandleNewTenant}>
              <div className="SaasForm">
                <div className="TenantForm">
                  <label className="label">
                    Company Name:
                    <input
                      type="text"
                      placeholder="My Company"
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    ></input>
                  </label>

                  <label className="label">
                    Company Email:
                    <input
                      type="email"
                      placeholder="example@example.com"
                      onChange={(e) => setCompanyEmail(e.target.value)}
                    ></input>
                  </label>

                  <label className="label">
                    Company Phone:
                    <input
                      type="tel"
                      placeholder="123-456-7890"
                      onChange={(e) => setCompanyPhone(e.target.value)}
                    ></input>
                  </label>

                  <label className="label">
                    Company Mobile:
                    <input
                      type="tel"
                      placeholder="123-456-7890"
                      onChange={(e) => setCompanyMobile(e.target.value)}
                    ></input>
                  </label>

                  <label className="label">
                    Company Address
                    <textarea
                      placeholder="123 Main St, City, Country"
                      onChange={(e) => setCompanyAddress(e.target.value)}
                    ></textarea>
                  </label>

                  <label className="label">
                    Company WebSite:
                    <input
                      type="text"
                      placeholder="www.example.com"
                      onChange={(e) => setCompanyWebSite(e.target.value)}
                    ></input>
                  </label>
                </div>
                <div className="ContactPersonForm">
                  <label className="label">
                    Company FAX No:
                    <input
                      type="text"
                      placeholder="123-456-7890"
                      onChange={(e) => setCompanyFax(e.target.value)}
                    ></input>
                  </label>
                  <label className="label">
                    Company TAX ID:
                    <input
                      type="text"
                      placeholder="XXXXXX"
                      onChange={(e) => setCompanyTaxID(e.target.value)}
                    ></input>
                  </label>
                  <label className="label">
                    Subscription Plan
                    <select>
                      <option value="">Select your subscription plan</option>
                      <option value="Tryial">Tryial</option>
                    </select>
                  </label>

                  <label className="label">
                    <Tooltip title="This person will be designated as the primary contact for the company.">
                      Contact Person Name:
                    </Tooltip>
                    <input
                      type="text"
                      placeholder="Abel Lemma"
                      onChange={(e) => setContactPerson(e.target.value)}
                      required
                    ></input>
                  </label>

                  <label className="label">
                    <Tooltip title="Please provide a valid email address for the contact person.">
                      Contact Person Email:
                    </Tooltip>
                    <input
                      type="email"
                      placeholder="example@example.com"
                      onChange={(e) => setContactPersonEmail(e.target.value)}
                      required
                    ></input>
                  </label>
                </div>
              </div>

              <button className="button" type="submit">
                Register
              </button>
            </form>
          </Spin>
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className="TenantRegistartionContainer">
      <ToastContainer />
      <>
        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        <div style={{ marginTop: 24 }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {/* {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )} */}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </>
      {/* <Spin spinning={loading}>
        <form className="form" onSubmit={HandleNewTenant}>
          <div className="SaasForm">
            <div className="TenantForm">
              <label className="label">
                Company Name:
                <input
                  type="text"
                  placeholder="My Company"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                ></input>
              </label>

              <label className="label">
                Company Email:
                <input
                  type="email"
                  placeholder="example@example.com"
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  
                ></input>
              </label>

              <label className="label">
                Company Phone:
                <input
                  type="tel"
                  placeholder="123-456-7890"
                  onChange={(e) => setCompanyPhone(e.target.value)}
                ></input>
              </label>

              <label className="label">
                Company Mobile:
                <input
                  type="tel"
                  placeholder="123-456-7890"
                  onChange={(e) => setCompanyMobile(e.target.value)}
                ></input>
              </label>

              <label className="label">
                Company Address
                <textarea
                  placeholder="123 Main St, City, Country"
                  onChange={(e) => setCompanyAddress(e.target.value)}
                ></textarea>
              </label>

              <label className="label">
                Company WebSite:
                <input
                  type="text"
                  placeholder="www.example.com"
                  onChange={(e) => setCompanyWebSite(e.target.value)}
                ></input>
              </label>

              <label className="label">
                Company FAX No:
                <input
                  type="text"
                  placeholder="123-456-7890"
                  onChange={(e) => setCompanyFax(e.target.value)}
                ></input>
              </label>

              <label className="label">
                Company TAX ID:
                <input
                  type="text"
                  placeholder="XXXXXX"
                  onChange={(e) => setCompanyTaxID(e.target.value)}
                ></input>
              </label>

              <label className="label">
                Subscription Plan
                <select>
                  <option value="">Select your subscription plan</option>
                  <option value="Tryial">Tryial</option>
                </select>
              </label>
            </div>
            <div className="ContactPersonForm">
              <label className="label">
                <Tooltip title="This person will be designated as the primary contact for the company.">
                  Contact Person Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="Abel Lemma"
                  onChange={(e) => setContactPerson(e.target.value)}
                  required
                ></input>
              </label>

              <label className="label">
                <Tooltip title="Please provide a valid email address for the contact person.">
                  Contact Person Email:
                </Tooltip>
                <input
                  type="email"
                  placeholder="example@example.com"
                  onChange={(e) => setContactPersonEmail(e.target.value)}
                  required
                ></input>
              </label>
            </div>
          </div>

          <button className="button" type="submit">
            Register
          </button>
        </form>
      </Spin> */}
    </div>
  );
};

export default TenantRegistration;
